import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProtocoloService {

  constructor(

    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
  ) { }

  getListProtocoloCombo(): Observable<any> {
    return this.http.get<any>(AppConstants.listProtocolo + '?noPaginate=1&delete=description&delete=create_date&delete=create_user&delete=last_update_date&delete=last_update_user&delete=is_active', {headers: AppConstants.getHeaders()});
  }

  getList(page: string, querystring = ""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listProtocolo + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  getUsuarioList(page: string): Observable<any> {
    var params = new HttpParams()
    .set('page', page);
    return this.http.get<any>(AppConstants.listUsuarios, {headers: AppConstants.getHeaders(), params: params});
  }

  consultarProtocolo(name: string,  description: string, isActive: boolean): Observable<any> {
    const headers = new HttpHeaders().append('Authorization', 'Token 36bbb878f1a983e05075e560fbcb9e672b8ce6cd');
    var params = new HttpParams()
    .set('name', name != null ? name : '')
    .set('description', description != null ? description : '')
    .set('isActive', isActive != false ? '1' : '0');

    return this.http.get<any>(AppConstants.listProtocolo, { headers: AppConstants.getHeaders(), params: params });
  }

  salvarProtocolo(protocolo): Observable<any> {
    return this.http.post<any>(AppConstants.salvarProtocolo, protocolo, {headers: AppConstants.getHeaders()});
  }

  carregarProtocolo(id): Observable<any> {
    return this.http.get<any>(AppConstants.listProtocolo + id + '/', {headers: AppConstants.getHeaders()});
  }

  atualizarProtocolo(protocolo): Observable<any> {
    return this.http.put<any>(AppConstants.salvarProtocolo + protocolo.id + '/', protocolo, {headers: AppConstants.getHeaders()});
  }

}
