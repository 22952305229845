import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from './../app-constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FabricanteService {

  constructor(private http: HttpClient) { }

  getFabricanteListCombo(): Observable<any> {
    return this.http.get<any>(AppConstants.listFabricantes + '?noPaginate=1&delete=create_date&delete=create_user&delete=last_update_date&delete=last_update_user&delete=is_active', {headers: AppConstants.getHeaders()});
  }

  getList(page: string, querystring = ""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listFabricantes + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  carregarFabricante(id): Observable<any> {
    return this.http.get<any>(AppConstants.listFabricantes + id + "/", {headers: AppConstants.getHeaders()});
  }

  salvarFabricante(fabricante): Observable<any> {
    return this.http.post<any>(AppConstants.salvarFabricantes, fabricante, {headers: AppConstants.getHeaders()});
  }

  atualizarFabricante(fabricante): Observable<any> {
    return this.http.put<any>(AppConstants.salvarFabricantes + fabricante.id + "/", fabricante, {headers: AppConstants.getHeaders()});
  }

  filtrarFabricante(name: string,isActive: boolean): Observable<any> {
    const headers = new HttpHeaders().append('Authorization', 'Token 36bbb878f1a983e05075e560fbcb9e672b8ce6cd');
    var params = new HttpParams()
    .set('name', name != null ? name : '')
    .set('isActive', isActive != false ? '1' : '0');

    return this.http.get<any>(AppConstants.listFabricantes, { headers: AppConstants.getHeaders(), params: params });
  }

}
