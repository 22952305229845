import { Component, OnInit } from '@angular/core';
import { FabricanteService } from 'src/app/service/fabricante.service';
import { Observable } from 'rxjs';
import {Fabricante} from 'src/app/model/Fabricante'
import { BaseListComponent } from '../base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fabricante',
  templateUrl: './fabricante.component.html',
  styleUrls: ['./fabricante.component.css']
})
export class FabricanteComponent extends BaseListComponent implements OnInit {

  constructor(private fabricanteService: FabricanteService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null,null, null, null, null);
    this.service = fabricanteService;
   }

  fabricantes: Observable<Fabricante[]>;
  name: string;
  isActive = true;
  service: any;
  columns: any;
  filters: any;

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Nome',
        value: 'name',
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'name'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      }
    ];
  }

  consultarFabricante() {
    this.fabricanteService.filtrarFabricante(this.name, this.isActive).subscribe(data => {
      this.fabricantes = data['results'];
    });
  }
}
