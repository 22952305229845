import { Empresa } from './../../model/Empresa';
import { TadsService } from './../../service/tads.service';
import { ToastyService } from 'ng2-toasty';
import { EmpresaService } from './../../service/empresa.service';
import { ComandoService } from './../../service/comando.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Tads } from 'src/app/model/Tads';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Comando } from 'src/app/model/Comando';

@Component({
  selector: 'app-novo-tad',
  templateUrl: './novo-tad.component.html',
  styleUrls: ['./novo-tad.component.css']
})
export class NovoTadComponent implements OnInit {

  public comboEmpresa: Array<Empresa>;
  public comand;

  public comando = new Comando;
  public id: number;
  public command: String;

  public sendDatetimeEquipment_lte;
  public sendDatetime_Equipment_gte;
  public collapse = false;

  constructor(
    private routeActive: ActivatedRoute,
    private tadsService: TadsService,
    private empresaService: EmpresaService,
    private comandoService: ComandoService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    spinner.show();
   }

   public page = 1;
   public maxPages;
   public numData;
   public itensPerPage;

  public tads = new Tads();
  public inicializacao;
  public total;
  public mensagens;
  public offline;
  public histInicializacoes;
  public dadosInterface;
  public ipExterno;
  public histConexao: Observable<Tads[]>;
  public listMensagens;

  public page_mensagens = 1;
  public maxPageMensagens;
  public page_inicializacao = 1;

  public maxPageInicializacao;
  public page_desconexao = 1;
  public maxPageDesconexao;
  public qtdadeComandos;

  public page_comando_enviado = 1;
  public maxPageComandoEnviado;

  public tipoDeTesteCombo;

  public ip;

  public idRenovaTad;
  public idComandoSelecionado;

  public com;
  public getComando;
  public getAllComand;
  public resposta;
  public idResultado;
  public totalComando;
  public tadId;

  public logs;
  public page_logs = 1;
  public totalLogs;
  public qtdadeLogs;
  public maxPageLogs;

  @Input() data: Object[];
  @Input() columns: Object[];
  @Input() filters: Object[];
  @Input() values: Object[];
  @Input() add_router: String;



  ngOnInit(): void {
    this.spinner.show();
    var id = this.routeActive.snapshot.paramMap.get('id');
    this.tadId = id;

    if (id != null) {
      this.tadsService.carregarTads(id).subscribe(data => {
        this.tads = data;
      });
    }

    else{
      this.tads.company = null;
    }

    // Preenche a combobox de empresas
    this.empresaService.getEmpresaCombo().subscribe(data => {
      this.comboEmpresa = data;
    });

    this.tadsService.listLocalizacao(this.ipExterno).subscribe(data => {
      this.ip = data;
    });

    if (id != null) {

        // Preenche o card view dados Inicializacoes
      this.tadsService.carregarInicializacao(id).subscribe(data => {
        this.inicializacao = data;
        this.total = this.inicializacao.total;
      });

      // Preenche o card view dados Mensagens
      this.tadsService.listQtdadeMensagens(id).subscribe(data => {
        this.inicializacao = data;
        this.mensagens = this.inicializacao.total;
      });

      // Preenche o card view dados Offline
      this.tadsService.carregarOffline(id).subscribe(data => {
        this.inicializacao = data;
        this.offline = this.inicializacao.total;
      });

      // Lista a tabela de HISTÓRICO DE INICIALIZAÇÕES
      this.tadsService.carregarHistInicializacoes(id).subscribe(data => {
        this.histInicializacoes = data['results'];
      });

      // Lista a tabela de HISTÓRICO DE CONEXÃO
      this.tadsService.carregarHistConexao(id).subscribe(data => {
        this.histConexao = data['results'];
      });

      // Lista de Mensagens
      this.tadsService.listMensagens(id).subscribe(data => {
        this.listMensagens = data['results'];
        this.maxPageMensagens = data['numPages'];
      });

      // Preenche a combobox
      this.tadsService.listCombobox(id).subscribe(data => {
        this.tipoDeTesteCombo = data;
      });

      this.tadsService.listaComando(id).subscribe(data => {
        this.getAllComand = data['results'];
        this.totalComando = this.getAllComand.length;
        this.qtdadeComandos = data['count'];
        this.maxPageComandoEnviado = data['numPages'];
      });

      this.tadsService.listLog(id).subscribe(data => {
        this.logs = data['results'];
        this.totalLogs = this.logs.length;
        this.qtdadeLogs = data['count'];
        this.maxPageLogs = data['numPages'];
      });

    }

    var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    }, 3000);

  }

  ngAfter() {
    this.renovar();
  }

  renovar() {
    this.idRenovaTad = this.tads.id;
    this.tadsService.renovarTad(this.idRenovaTad).subscribe(data => {
      this.tads = data;
    });

  }

  enviarComando() {

    this.idRenovaTad = this.tads.id;
    this.comando.id = this.comand.id;
    this.comando.command = this.comand.command;
    this.tadsService.enviarComando(this.idRenovaTad, this.comando).subscribe(data => {


      this.tadsService.listaComando(this.tadId).subscribe(data => {
        this.getAllComand = data['results'];
        this.totalComando = this.getAllComand.length;
        this.qtdadeComandos = data['count'];
        this.maxPageComandoEnviado = data['numPages'];
        this.page_comando_enviado = 1;
      });
      this.toasty.success('Comando enviado com sucesso!');
    });


  }

  salvarTads() {

    this.spinner.show();

    for (var prop in this.tads) {
      if (this.tads[prop] == 'undefined' || this.tads[prop] === ""){
        this.tads[prop] = null;
      }
    }

    if (this.tads.id != null) {
      this.tadsService.atualizarTads(this.tads).subscribe(data => {
        if (data != null) {
          this.toasty.success('TAD salvo com sucesso!');
          this.router.navigate(['tads']);
        }
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      }, (err) => {
        try {
          if (err.error.errorCode == 17) {
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.toasty.error('MQTT ID já existente!');
          } else {
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.toasty.error('Serial number já existente');
          }
        } catch (exeption) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
          this.toasty.error('Erro ao cadastrar TADs');
        }
      });
    } else {
      this.tadsService.salvarTads(this.tads).subscribe((data) => {
        if (data != null) {
          this.toasty.success('TAD salvo com sucesso');
          this.router.navigate(['tads']);
        }
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      }, (err) => {
        try {
          if (err.error.errorCode == 17) {
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.toasty.error('MQTT ID já existente!');
          } else {
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.toasty.error('Serial number já existente');
          }
        } catch (exeption) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
          this.toasty.error('Erro ao cadastrar TADs');
        }
      });
    }
  }

  abrirModal(histInicializacoes, modal)  {
    this.spinner.show();

    this.ipExterno = histInicializacoes['externalIp'];
    this.tadsService.listLocalizacao(this.ipExterno).subscribe(data => {
      this.ip = data;

      var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    });

    });

    this.dadosInterface = histInicializacoes['interfaces'];
    this.modalService.open(modal, {size: 'lg'});
  }

  open(content) {
    this.spinner.show();

    this.modalService.open(content, {size: 'lg'});
    this.tadsService.listarComando().subscribe(data => {
      this.com = data;

      var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    }),(err) => {
      var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    })

    };

    });
  }

  openResult(getAllComand, content1) {
    this.spinner.show();
    let getIdResposta = getAllComand['id'];
    this.tadsService.listResultado(getIdResposta, this.id).subscribe(data => {
      this.resposta = data['response'];
      var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    });
    });
    this.modalService.open(content1, {size: 'lg'});
  }



  compararCombo(item1,item2){

    if (item1 == null && item2 == null){
      return true;
    }

    try {
      return item1.id == item2.id
    }
    catch(err) {
      return false;
    }
  }

  navigatePage(new_page: string) {
    if (this.page_mensagens < this.maxPageMensagens || new_page != '0') {
        this.spinner.show();
        this.tadsService.listMensagens(this.tads.id, new_page).subscribe(data => {
          this.listMensagens = data['results'];
          this.maxPageMensagens = data['numPages'];
          this.page_mensagens = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
    }
  }


  // Metodo para paginacao de inicializacao
  navigateInicializacaoPage(new_page: string) {
    if (this.page_inicializacao < this.maxPageInicializacao || new_page != '0') {
        this.spinner.show();
        this.tadsService.carregarHistInicializacoes(this.tads.id, new_page).subscribe(data => {
          this.histInicializacoes = data['results'];
          this.maxPageInicializacao = data['numPages'];
          this.page_inicializacao = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
    }
  }

// Metodo para paginacao de desconexao
  navigateDesconexaoPage(new_page: string) {
    if (this.page_desconexao < this.maxPageDesconexao || new_page != '0') {
        this.spinner.show();
        this.tadsService.carregarHistConexao(this.tads.id, new_page).subscribe(data => {
          this.histConexao = data['results'];
          this.maxPageDesconexao = data['numPages'];
          this.page_desconexao = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
    }
  }

  // Metodo para paginacao de comandos enviados
  navigateComandosEnviadosPage(new_page: string) {
    if (this.page_comando_enviado < this.maxPageComandoEnviado || new_page != '0') {
        this.spinner.show();
        this.tadsService.listaComando(this.tads.id, new_page).subscribe(data => {
          this.getAllComand = data['results'];
          this.qtdadeComandos = data['count'];
          this.maxPageComandoEnviado = data['numPages'];
          this.page_comando_enviado = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
    }
  }

  // Metodo para paginacao de logs do aplicativo
  navigateLogPage(new_page: string) {
    if (this.page_logs < this.maxPageLogs || new_page != '0') {
        this.spinner.show();
        this.tadsService.listLog(this.tads.id, new_page).subscribe(data => {
          this.logs = data['results'];
          this.qtdadeLogs = data['count'];
          this.maxPageLogs = data['numPages'];
          this.page_logs = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
    }
  }


  filtro() {
    this.spinner.show();
    var form = <HTMLFormElement>document.getElementById('mensagens');
    var els = form.elements;
    var i;
    var querystring = '';

    for (i = 0; i < els.length; i++) {
      var inp = <HTMLInputElement>els[i];
      var name = inp.name;
      var value = inp.value;
      if (name != '' && value != '' && value != 'null') {
          querystring += name + '=' + value + '&';
      }
    }
    querystring = querystring.slice(0, -1);

    this.tadsService.listMensagens(this.tads.id, this.page.toString(), querystring).subscribe(data => {
      this.listMensagens = data['results'];
      this.maxPageMensagens = data['numPages'];
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      }, 100);
    });
  }

  collapse_filter(){
    this.collapse = !this.collapse;
  }



}
