import {Component, Input, OnInit} from '@angular/core';
import DateTimeFormat = Intl.DateTimeFormat;
import {Usuario} from '../../model/Usuario';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.css']
})
export class BaseFormComponent implements OnInit {
  @Input() create_date: DateTimeFormat;
  @Input() create_user: Usuario;
  @Input() last_update_date: DateTimeFormat;
  @Input() last_update_user: Usuario;
  constructor() { }

  ngOnInit(): void {
  }

}
