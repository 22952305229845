import { NgxSpinnerService } from 'ngx-spinner';
import { BaseListComponent } from './../base-list/base-list.component';
import { ResultadosService } from './../../service/resultados.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Tads } from 'src/app/model/Tads';
import { TadMessage } from 'src/app/model/TadMessage';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.css']
})
export class ResultadosFincareSeriesComponent extends BaseListComponent implements OnInit {

  tadMessage: Observable<TadMessage[]>;

  public resultado;

  constructor(
    private resultadosService: ResultadosService,
    private baseListComponent: BaseListComponent,
    spinner: NgxSpinnerService
  ) {
    super(spinner, null, null,null, null, null, null);
    this.service = resultadosService;
   }

  ngOnInit(): void {

    this.columns = [
      {
        nome: 'Data de Recebimento',
        value: 'createDate',
      },
      {
        nome: 'Identificador',
        value: 'identifier',
      },
      {
        nome: 'Resultado',
        value: 'result',
      },
      {
        nome: 'Data Teste',
        value: 'sendDatetimeEquipment',
      },
      {
        nome: 'Tipo',
        value: 'type',
      },
      {
        nome: 'Protocolo',
        value: 'protocol.name',
      },
      {
        nome: 'Nome',
        value: 'tad.name',
      },
      {
        nome: 'Empresa',
        value: 'company'
      },
      {
        nome: 'Telefone',
        value: 'sendByPhone'
      }
    ];

    this.filters = [
      {
        nome: 'Empresa',
        field: 'company'
      },
      {
        nome: 'TAD',
        field: 'tad'
      },
      {
        nome: 'Data Teste DE',
        field: 'sendDatetimeEquipmentGte'
      },
      {
        nome: 'Data Teste ATÉ',
        field: 'sendDatetimeEquipmentLte'
      },
      {
        nome: 'Tipo de Exame',
        field: 'typeExam'
      }
    ];
  }

}


