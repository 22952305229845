import { TadsService } from './service/tads.service';
import { ToastyModule } from 'ng2-toasty';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { EsqueciMinhaSenhaComponent } from './pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { NovoUsuarioComponent } from './pages/novo-usuario/novo-usuario.component';
import { EditarUsuarioComponent } from './pages/editar-usuario/editar-usuario.component';
import { FabricanteComponent } from './pages/fabricante/fabricante.component';
import { NovoFabricanteComponent } from './pages/novo-fabricante/novo-fabricante.component';
import { EditarFabricanteComponent } from './pages/editar-fabricante/editar-fabricante.component';
import { RedefinirSenhaComponent } from './pages/redefinir-senha/redefinir-senha.component';
import { ComandoComponent } from './pages/comando/comando.component';
import { NovoComandoComponent } from './pages/novo-comando/novo-comando.component';
import { EquipamentoComponent } from './pages/equipamento/equipamento.component';
import { ProtocoloComponent } from './pages/protocolo/protocolo.component';
import { NovoProtocoloComponent } from './pages/novo-protocolo/novo-protocolo.component';
import { NovoEquipamentoComponent } from './pages/novo-equipamento/novo-equipamento.component';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';
import { BaseListComponent } from './pages/base-list/base-list.component';
import { UsuarioService } from './service/usuario.service';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { NovoEmpresaComponent } from './pages/novo-empresa/novo-empresa.component';
import { TadsComponent } from './pages/tads/tads.component';
import { NovoTadComponent } from './pages/novo-tad/novo-tad.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BaseFormComponent } from './pages/base-form/base-form.component';
import { InputMaskAngularModule } from 'input-mask-angular';
import { PaginaNaoEncontradaComponent } from './pages/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { ResultadosFincareSeriesComponent } from './pages/resultados/resultados.component';
import { ResultadosIponaticComponent } from './pages/resultados-iponatic/resultados.component';


import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DashComponent } from './pages/dash/dash.component';
import { AbacusComponent } from './pages/abacus/abacus.component';
import { GasometriaComponent } from './pages/gasometria/gasometria.component';
import { IcounterComponent } from './pages/icounter/icounter.component';
registerLocaleData(localePt);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    InputMaskAngularModule,
    ReactiveFormsModule,

    ToastyModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    EsqueciMinhaSenhaComponent,
    UsuarioComponent,
    NovoUsuarioComponent,
    EditarUsuarioComponent,
    FabricanteComponent,
    NovoFabricanteComponent,
    EditarFabricanteComponent,
    RedefinirSenhaComponent,
    ComandoComponent,
    NovoComandoComponent,
    EquipamentoComponent,
    ProtocoloComponent,
    NovoProtocoloComponent,
    NovoEquipamentoComponent,
    TrocarSenhaComponent,
    BaseListComponent,
    EmpresaComponent,
    NovoEmpresaComponent,
    TadsComponent,
    NovoTadComponent,
    BaseFormComponent,
    PaginaNaoEncontradaComponent,
    DashComponent,
    ResultadosFincareSeriesComponent,
    ResultadosIponaticComponent,
    AbacusComponent,
    GasometriaComponent,
    IcounterComponent
  ],
  providers: [DatePipe, BaseListComponent, UsuarioService, TadsService, { provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
