import {ComandoService} from './../../service/comando.service';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Comando} from 'src/app/model/Comando';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-novo-comando',
  templateUrl: './novo-comando.component.html',
  styleUrls: ['./novo-comando.component.css']
})
export class NovoComandoComponent implements OnInit {

  public comando = new Comando();

  constructor(
    private routeActive: ActivatedRoute,
    private comandoService: ComandoService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');

    if (id != null) {
      this.comandoService.carregarComando(id).subscribe(data => {
        this.comando = data;
      });
    }
    var that = this;
    window.setTimeout(function (spinner) {
      that.spinner.hide();
    }, 300);
  }

  salvarComando() {
    this.spinner.show();

    for (var prop in this.comando) {
      if (this.comando[prop] == 'undefined' || this.comando[prop] === '') {
        this.comando[prop] = null;
      }
    }

    if (this.comando.id != null) {
      this.comandoService.atualizarComando(this.comando).subscribe(data => {
          if (data != null) {
            this.toasty.success('Comando salvo com sucesso');
            this.router.navigate(['comando']);
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        },
        (err) => {
          try {
            if (err.error.errorCode == 20) {
              this.toasty.error('Comando já existente');
            }
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          } catch (exeption) {
            this.toasty.error('Erro ao atualizar comando');
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          }
        });
    } else {

      this.comandoService.salvarComando(this.comando).subscribe((data) => {
          if (data != null) {
            this.toasty.success('Comando salvo com sucesso!');
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.router.navigate(['comando']);

          }
        },
        (err) => {
          try {
            if (err.error.errorCode == 20) {
              this.toasty.error('Comando já existente');
            }
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          } catch (exeption) {
            this.toasty.error('Erro ao cadastrar comando');
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          }
        });
    }
  }
}
