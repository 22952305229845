import { element } from 'protractor';
import { LoginServiceService } from './../../service/login-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
    /* { path: '/icons', title: 'Icons',  icon: 'ni-planet text-blue', class: '' },
    { path: '/maps', title: 'Maps',  icon: 'ni-pin-3 text-orange', class: '' },
    { path: '/user-profile', title: 'User profile',  icon: 'ni-single-02 text-yellow', class: '' }, */
   /*  { path: '/tables', title: 'Tables',  icon: 'ni-bullet-list-67 text-red', class: '' }, */
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-red', class: '' },
    { path: '/usuario', title: 'Usuarios',  icon: 'ni-single-02 text-red', class: '' },
    { path: '/fabricante', title: 'Fabricantes',  icon: 'ni ni-delivery-fast text-red', class: '' },
    { path: '/comando', title: 'Comando',  icon: 'ni ni-tablet-button text-red', class: '' },
    { path: '/equipamento', title: 'Equipamento',  icon: 'ni ni-settings text-red', class: '' },
    { path: '/protocolo', title: 'Protocolo',  icon: 'ni ni-folder-17 text-red', class: '' },
    { path: '/empresa', title: 'Empresa',  icon: 'ni ni-building text-red', class: '' },
    { path: '/tads', title: 'Tads',  icon: 'ni ni-email-83 text-red', class: '' },
    { path: '/resultados-fincare-series', title: 'Finecare Series', icon: 'ni ni-poll text-red', class: '' },
    { path: '/resultados-iponatic', title: 'Iponatic', icon: 'ni ni-poll text-red', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  public usuario: String;
  public perfil: String;
  public caminho: String;
  public flag: Boolean;

  public esconder: false;

  constructor(private router: Router, loginServiceService: LoginServiceService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
   if (localStorage.getItem('user')) {
    let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'a@Gt*kjnFRPf');
    this.usuario = JSON.parse(user.toString(CryptoJS.enc.Utf8)).email;
    this.perfil = JSON.parse(user.toString(CryptoJS.enc.Utf8)).profile;
   }else {
    this.router.navigate(['/login']);
   }

  }

  onLogout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
