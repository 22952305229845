import { BaseListComponent } from './../base-list/base-list.component';
import { EmpresaService } from './../../service/empresa.service';
import { Empresa } from './../../model/Empresa';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent extends BaseListComponent implements OnInit {

  empresa: Observable<Empresa[]>;
  name: string;
  isActive = true;
  columns: any;
  filters: any;

  constructor(private empresaService: EmpresaService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null, null, null, null,null);
    this.service = empresaService;
   }

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Nome',
        value: 'name'
      },
      {
        nome: 'CNPJ',
        value: 'cnpj'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      },

    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'name'
      },
      {
        nome: 'CNPJ',
        field: 'cnpj'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      },

    ];
  }


}
