import {Component, OnInit, Input, Inject, ChangeDetectorRef} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {EmpresaService} from 'src/app/service/empresa.service';
import {ProtocoloService} from 'src/app/service/protocolo.service';
import {FabricanteService} from 'src/app/service/fabricante.service';
import {ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.css']
})
export class BaseListComponent implements OnInit {

  constructor(public spinner: NgxSpinnerService,
              private empresaServiceBase: EmpresaService,
              private fabricanteServiceBase: FabricanteService,
              public protocoloServiceBase: ProtocoloService,
              private router: Router,
              private toasty: ToastyService,
              private modalService: NgbModal) {
    spinner.show();
  }

  @Input() data: Object[];
  @Input() columns: Object[];
  @Input() filters: Object[];
  @Input() values: Object[];
  @Input() add_router: String;
  @Input() ocultar = '0';

  public page = 1;
  public maxPages;
  public numData;
  public itensPerPage;
  public comboEmpresa: Object[];
  public comboFabricante: Object[];
  public comboProtocolo: Object[];
  public comboTad: Object[];
  public collapse = false;
  public custom_page_number;
  public isAtivado = false;

  public rota;
  public abacusExam;
  public eritograma;
  public leucograma;
  public plaquetograma;
  public gasometria = [];
  public icounter = [];
  public icounter_eritograma =[];
  public icounter_leucograma =[];
  public icounter_placetograma =[];
  public show_reference_values = false;
  public listExport = [];
  public exam_types: Object[];

  @Input() service: any;

  ngOnInit(): void {

    this.service.getList(this.page.toString()).subscribe(data => {
      this.custom_page_number = this.page;
      this.data = data['results'];
      this.maxPages = data['numPages'];
      this.numData = data['count'];
      this.itensPerPage = data['itensPerPage'];
      this.rota = this.add_router;
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      });
    });

    this.filters.forEach(element => {
      if (element['field'] == 'typeExam'){
        this.service.listExamTypes().subscribe(data=> {
          this.exam_types = data;
        });
      }

      if (element['field'] == 'company') {
        this.empresaServiceBase.getEmpresaCombo().subscribe(data => {
          this.comboEmpresa = data;
        });
      }

      if (element['field'] == 'manufacturer') {
        this.fabricanteServiceBase.getFabricanteListCombo().subscribe(data => {
          this.comboFabricante = data;
        });
      }

      if (element['field'] == 'protocol') {
        this.protocoloServiceBase.getListProtocoloCombo().subscribe(data => {
          this.comboProtocolo = data;
        });
      }

      if (element['field'] == 'tad') {
        this.empresaServiceBase.getTadCombo().subscribe(data => {
          this.comboTad = data;
        });
      }
    });
  }

  ativarDesativar() {
    this.isAtivado = true;
  }

  navigatePage(new_page: string) {

    if (this.page < this.maxPages || new_page != '0') {

    var form = <HTMLFormElement>document.getElementById('form-filtro');
    var els = form.elements;
    var i;
    var querystring = '';
    for (i = 0; i < els.length; i++) {
      var inp = <HTMLInputElement>els[i];
      var name = inp.name;
      var value = inp.value;
      if (name != '' && value != '' && value != 'null') {

        if (name == 'isActive' && inp.checked && this.isAtivado) {
          querystring += name + '=1&';
        } else if (name == 'isActive' && !inp.checked && this.isAtivado) {
          querystring += name + '=0&';
        } else if (name != 'isActive') {
          querystring += name + '=' + value + '&';
        }
      }
    }
    querystring = querystring.slice(0, -1);

      this.spinner.show();
      this.service.getList(new_page, querystring).subscribe(data => {
        this.data = data['results'];
        this.maxPages = data['numPages'];
        this.numData = data['count'];
        this.itensPerPage = data['itensPerPage'];
        this.page = Number(new_page);
        this.custom_page_number = this.page;


        var that = this;
        window.setTimeout(function (spinner) {
        that.spinner.hide();
      });
      // prever o erro depois do carregamento

      });
    }

  }

  checkTypeObject(value) {
    if (value.includes('.')) {
      return true;
    }
    return false;
  }

  getItemObject(value, index) {
    return value.split('.')[index];
  }

  limparFiltro() {
    this.isAtivado = false;
    this.spinner.show();
    var form = <HTMLFormElement>document.getElementById('form-filtro');
    form.reset();

    this.service.getList(this.page.toString(), '').subscribe(data => {
      this.data = data['results'];
      this.maxPages = data['numPages'];
      this.numData = data['count'];
      this.itensPerPage = data['itensPerPage'];
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      }, 100);
    });
  }

  filtrar() {
    this.isAtivado = true;
    this.spinner.show();
    var form = <HTMLFormElement>document.getElementById('form-filtro');
    var els = form.elements;
    var i;
    var querystring = '';
    for (i = 0; i < els.length; i++) {
      var inp = <HTMLInputElement>els[i];
      var name = inp.name;
      var value = inp.value;
      if (name != '' && value != '' && value != 'null') {

        if (name == 'isActive' && inp.checked && this.isAtivado) {
          querystring += name + '=1&';
        } else if (name == 'isActive' && !inp.checked && this.isAtivado) {
          querystring += name + '=0&';
        } else if (name != 'isActive') {
          querystring += name + '=' + value + '&';
        }
      }
    }
    querystring = querystring.slice(0, -1);

    this.service.getList('1', querystring).subscribe(data => {
      this.data = data['results'];
      this.maxPages = data['numPages'];
      this.numData = data['count'];
      this.itensPerPage = data['itensPerPage'];
      this.page = 1;
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      }, 100);
    });
  }

  export() {
    this.isAtivado = true;
    this.spinner.show();
    var form = <HTMLFormElement>document.getElementById('form-filtro');
    var els = form.elements;
    var i;
    var querystring = '';
    for (i = 0; i < els.length; i++) {
      var inp = <HTMLInputElement>els[i];
      var name = inp.name;
      var value = inp.value;
      if (name != '' && value != '' && value != 'null') {

        if (name == 'isActive' && inp.checked && this.isAtivado) {
          querystring += name + '=1&';
        } else if (name == 'isActive' && !inp.checked && this.isAtivado) {
          querystring += name + '=0&';
        } else if (name != 'isActive') {
          querystring += name + '=' + value + '&';
        }
      }
    }
    querystring = querystring.slice(0, -1);
    querystring = querystring + '&ids=' + this.listExport.toString();



    this.service.export(querystring).subscribe(data => {
      this.toasty.success('A exportação dos resultados foi solicitada com sucesso. Por favor, verifique sua caixa de e-mail dentro de instantes.');
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      }, 100);
    });
  }

  new_data() {
    this.router.navigate([this.add_router]);
  }

  collapsable() {
    this.collapse = !this.collapse;
    if (!this.collapse) {
      document.getElementById('none').style.display = 'none';
    } else {
      document.getElementById('none').style.display = 'block';
    }

  }

  converterData(idInput) {
      var element = <HTMLInputElement> document.getElementById(idInput);
      let dataInput = element.value;
      if (dataInput != '') {
        element.value = dataInput[8] + dataInput[9] + '/' + dataInput[5] + dataInput[6] + '/' + dataInput[0] + dataInput[1] + dataInput[2] + dataInput[3];
      }
  }

  custom_page(){
    if (Number(this.custom_page_number) < 1) {
      this.navigatePage('1');
    }

    else if (Number(this.custom_page_number) > Number(this.maxPages)) {
      this.navigatePage(this.maxPages);
    }
    else{
      this.navigatePage(this.custom_page_number);
    }
  }

  open(content, data, rota) {

    this.icounter = [];
    this.icounter_eritograma = [];
    this.icounter_leucograma= [];
    this.icounter_placetograma = [];

    if(rota ==="/resultados-abacus" || rota === "/resultados-gasometria") {
      this.abacusExam = data;


      this.gasometria = data.results


      let gasometria_order = ['pH', 'pO2', 'pCO2', 'sO2(est)', 'Hct', 'POP', 'tHb(est)', 'Lac', 'Na+', 'K+', 'Cl-', 'Ca++', 'Ca++(7.4)', 'Glu']
      let tmp = [];
      for (let item of gasometria_order) {
        for (let result of this.gasometria) {
          if (result["observationIdentifier"] == item) {
            tmp.push(result)
          }
        }
      }

      for (let item of this.gasometria) {
        if (!gasometria_order.includes(item["observationIdentifier"])) {
          tmp.push(item)
        }
      }

      this.gasometria = tmp;


      this.eritograma = data.results.filter(item => {
        return new Set(['RBC', 'HGB', 'HCT', 'MCV', 'RDWs', 'MCH', 'MCHC', 'RDWc']).has(item['observationIdentifier']);
      });

      this.leucograma = data.results.filter(item => {
        return new Set(['WBC', 'LYM', 'MON', 'NEU', 'EOS', 'BAS', 'LYM%', 'MON%', 'NEU%', 'EOS%', 'BAS%']).has(item['observationIdentifier']);
      });

      this.plaquetograma = data.results.filter(item => {
        return new Set(['PLT', 'MPV', 'PCT', 'PDWc', 'PDWs']).has(item['observationIdentifier']);
      });
      this.modalService.open(content, {size: 'lg'});
    }
    else if (rota==="/resultados-icounter"){

      this.icounter.push(data["lyn"]);
      this.icounter.push(data["mid"]);
      this.icounter.push(data["neu"]);
      this.icounter.push(data["eos"]);
      this.icounter.push(data["wbc"]);
      //
       this.icounter.push(data["rbc"]);
       this.icounter.push(data["hgb"]);
       this.icounter.push(data["hct"]);
       this.icounter.push(data["mcv"]);
       this.icounter.push(data["mch"]);
       this.icounter.push(data["mchc"]);
       this.icounter.push(data["rdwCv"]);
       this.icounter.push(data["rdwSd"]);
       this.icounter.push(data["plt"]);
       this.icounter.push(data["mpv"]);
       this.icounter.push(data["pct"]);
       this.icounter.push(data["pdwCv"]);
       this.icounter.push(data["pdwSd"]);
       this.icounter.push(data["plcr"]);
       this.icounter.push(data["plcc"]);
      data["wbc"]["name"] = "GB";
      data["wbc"]["p"] = data["wbc"]["unit"];

      data["lyn"]["name"] = "LIN";
      data["lyn"]["p"] = data["lyn"]["unit"] + " - " + data["lynp"]["value"];
      data["mid"]["name"] = "MID";
      data["mid"]["p"] = data["mid"]["unit"] + " - " + data["midp"]["value"];


      data["neu"]["name"] = "NEU";
      data["neu"]["p"] = data["neu"]["unit"] + " - " + data["neup"]["value"];

      data["eos"]["name"] = "EOS";
      data["eos"]["p"] = data["eos"]["unit"] + " - " + data["eosp"]["value"];
      data["rbc"]["name"] = "GV";
      data["hgb"]["name"] = "HGB";
      data["hct"]["name"] = "HCT";
      data["mcv"]["name"] = "VCM";
      data["mch"]["name"] = "HCM";
      data["mchc"]["name"] = "CHCM";
      data["rdwCv"]["name"] = "RDW-CV";
      data["rdwSd"]["name"] = "RDW-SD";

      data["plt"]["name"] = "PLT";
      data["mpv"]["name"] = "VPM";
      data["pct"]["name"] = "PCT";
      data["pdwCv"]["name"] = "PDW-CV";
      data["pdwSd"]["name"] = "PDW-SD";
      data["plcr"]["name"] = "PLC-R";
      data["plcc"]["name"] = "PLC-C";

      this.icounter_eritograma = this.icounter.filter(item => {
        return new Set(['GV','HGB','HCT','VCM','HCM', 'CHCM', 'RDW-CV', 'RDW-SD']).has(item['name']);
      });

      this.icounter_placetograma = this.icounter.filter(item => {
        return new Set(['PLT','VPM','PCT','PDW-CV','PDW-SD', 'PLC-R', 'PLC-C']).has(item['name']);
      });

      this.icounter_leucograma = this.icounter.filter(item => {
        return new Set(['GB','LIN','MID','NEU','EOS']).has(item['name']);
      });


      this.modalService.open(content, {size: 'lg'});
    }

  }

  format_unit(unit){
    if (unit.includes('^')){
      return unit.replace('^', '<sup>').replace('/', '</sup>/')
    }
    return unit
  }

  format_icounter(unit){
    if (unit.includes('^')){
      return unit.replace('^', '<sup>').replace('/', '</sup>/')
    }
    return unit
  }

  format_value_gasometria(value){
    return value.replace('^^', ' ').replace('↓', '')
  }

  format_value_icounter(value){
    return value.replace('^^', ' ').replace('↓', '')
  }

  show_arrow_gasometria(value){
    if(value.includes('↓')){
      return 'down';
    }

    else if (value.includes('↑')){
      return 'up'
    }
    return '';
  }

  handle_show_reference_values(){
    this.show_reference_values = !this.show_reference_values;
  }

  check_reference_value(reference_value, value){
    let min = Number(reference_value.split('-')[0]);
    let max = Number(reference_value.split('-')[1]);
    let c_value = Number(value);

    if (value > max){
      return "up";
    }

    else if(value < min){
      return "down";
    }
    return null;
  }

  handle_list_export_items(event, object){

    if (event.target.checked){
      this.listExport.push(object.id);
    }
    else{
      this.listExport = this.listExport.filter(id => id !== object.id)
    }
  }
  check_export_item(object){
    return this.listExport.indexOf(object.id) > -1;
  }

}
