import { TrocarSenha } from './../model/TrocarSenha';
import { Observable } from 'rxjs';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class RedefinirSenhaService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
    ) { }

  getRedefinirSenha(newPassword, id): Observable<any> {
    return this.http.post<TrocarSenha>(AppConstants.baseRedefinirSenhaLogado(id), {newPassword: newPassword.newPassword}, {headers: AppConstants.getHeaders()});
  }

  getRedefinirSenhaDeslogado(token: string, newPassword) {
    let headers = new HttpHeaders().append('Authorization', "Token " + token);
    return this.http.post<any>(AppConstants.baseRedefinirSenhaDeslogado(), {newPassword: newPassword}, {headers: headers});
  }


}
