import { Empresa } from './Empresa';
import { Audit } from './Audit';

export class Tads extends Audit {
  id: number;
  name: String;
  serialNumber: String;
  description: String;
  mqttId: String;
  mqttUsername: String;
  mqttPassword: String;
  lastSync: String;
  company: Empresa;
  isActive: boolean;
  status: String;
  disconnectReport: boolean;
}
