import { HttpHeaders } from '@angular/common/http';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RedefinirSenhaService } from './../../service/redefinir-senha.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent implements OnInit {

  senha: string;
  confirmarPassword: string;

  constructor(
    private redefinirSenhaService: RedefinirSenhaService,
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService

    ) { }

  ngOnInit(): void {
  }

  salvar() {
    let url = window.location.href;
    let lista = url.split("/");
    let token = lista[lista.length - 1];
    let headers = new HttpHeaders().append('Authorization', "Token " + token);
    if (this.senha === this.confirmarPassword) {
      this.redefinirSenhaService.getRedefinirSenhaDeslogado(token, this.confirmarPassword).subscribe(data => {
        this.router.navigate(['login']);
      this.toasty.success('Senha alterada com sucesso!');
      });
    } else {
      this.toasty.error('A senha não confere');
    }

  }


}
