import { Component } from '@angular/core';
import { ToastyConfig } from 'ng2-toasty';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';

  constructor(private toastConfig: ToastyConfig, private spinner: NgxSpinnerService) {
    this.toastConfig.theme = 'bootstrap';
  }
}
