import {ProtocoloService} from './../../service/protocolo.service';
import {EquipamentoService} from './../../service/equipamento.service';
import {ToastyService} from 'ng2-toasty';
import {FabricanteService} from './../../service/fabricante.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Equipamento} from 'src/app/model/Equipamento';
import {Fabricante} from 'src/app/model/Fabricante';
import {Protocolo} from 'src/app/model/Protocolo';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-novo-equipamento',
  templateUrl: './novo-equipamento.component.html',
  styleUrls: ['./novo-equipamento.component.css']
})
export class NovoEquipamentoComponent implements OnInit {

  public comboManufacturer: Array<Fabricante>;
  public comboProtocol: Array<Protocolo>;

  constructor(
    private routeActive: ActivatedRoute,
    private equipamentoService: EquipamentoService,
    private fabricanteService: FabricanteService,
    private protocoloService: ProtocoloService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService
  ) {
  }

  public equipamento = new Equipamento();

  ngOnInit(): void {
    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');
    // Listagem da combobox de Fabricante
    this.fabricanteService.getFabricanteListCombo().subscribe(data => {
      this.comboManufacturer = data;
    });

    this.protocoloService.getListProtocoloCombo().subscribe(data => {
      this.comboProtocol = data;
    });

    if (id != null) {
      this.equipamentoService.carregarEquipamento(id).subscribe(data => {
        this.equipamento = data;
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      });
    } else {
      this.equipamento.protocol = null;
      this.equipamento.manufacturer = null;
      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      });
    }
  }

  salvarEquipamento() {
    this.spinner.show();

    for (var prop in this.equipamento) {
      if (this.equipamento[prop] == 'undefined' || this.equipamento[prop] === '') {
        this.equipamento[prop] = null;
      }
    }

    if (this.equipamento.id != null) {
      this.equipamentoService.atualizarEquipamento(this.equipamento).subscribe(data => {
          if (data != null) {
            this.toasty.success('Equipamento salvo com sucesso');
            this.router.navigate(['equipamento']);
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        },
        (err) => {
          try {
            if (err.error.errorCode == 22) {
              this.toasty.error('Número serial já existente');
            }
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          } catch (exeption) {
            this.toasty.error('Erro ao atualizar equipamento');
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          }
        });
    } else {
      this.equipamentoService.salvarEquipamento(this.equipamento).subscribe((data) => {
        if (data != null) {
          this.toasty.success('Equipamento salvo com sucesso');
          this.router.navigate(['equipamento']);
        }
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      }, (err) => {
        try {
          if (err.error.errorCode == 22) {
            this.toasty.error('Número serial já existente');
          } else {
            this.toasty.error('Erro ao cadastrar equipamento');
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar equipamento');
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        }
      });
    }
  }

  compararCombo(item1, item2) {

    if (item1 == null && item2 == null) {
      return true;
    }

    try {
      return item1.id == item2.id;
    } catch (err) {
      return false;
    }
  }
}
