import {TadsService} from './../../service/tads.service';
import {Empresa} from './../../model/Empresa';
import {EmpresaService} from './../../service/empresa.service';
import {ToastyService} from 'ng2-toasty';
import {Phones} from './../../model/Phones';
import {Usuario} from './../../model/Usuario';
import {UsuarioService} from './../../service/usuario.service';
import {Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-novo-usuario',
  templateUrl: './novo-usuario.component.html',
  styleUrls: ['./novo-usuario.component.css']
})
export class NovoUsuarioComponent implements OnInit {

  data_list: any;
  maxPages_list: any;
  numData_list: any;
  itensPerPage_list: any;
  page_list: any;
  public page = 1;

  public email = new FormControl('', Validators.email);

  public usuario = new Usuario();
  public company = new Empresa();
  public phones = new Phones();
  public add_phone: String;
  public add_description: String;
  public keyword: String;

  public comboEmpresa: Array<Empresa>;

  pt: any;

  public perfil;

  public list;

  public qtdadeTads;

  public checkedAll = false;

  public tadSemPaginacao;

  constructor(
    private routeActive: ActivatedRoute,
    private usuarioService: UsuarioService,
    private empresaService: EmpresaService,
    private tadsService: TadsService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {

  }

  ngOnInit(): void {

    this.pt = {
      firstDayOfWeek: 0,
      dayNames:
        [
          'Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'
        ],
      dayNamesShort:
        [
          'Sun', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
        ],
      dayNamesMin:
        [
          'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
        ],
      monthNames:
        [
          'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ],
      monthNamesShort:
        [
          'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Aug', 'Set', 'Out', 'Nov', 'Dez'
        ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };


    this.usuario.tads = new Array();

    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');

    if (id != null) {
      this.usuarioService.carregarUsuario(id).subscribe(data => {
        this.usuario = data;
        this.qtdadeTads = this.usuario.tads.length;
      });
    } else {
      this.usuario.phones = new Array<Phones>();
      this.usuario.company = null;
      this.usuario.profile = null;
    }

    let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'a@Gt*kjnFRPf');
    this.perfil = JSON.parse(user.toString(CryptoJS.enc.Utf8)).profile;

    if (this.perfil == 'super-admin') {
      this.empresaService.getEmpresaCombo().subscribe(data => {
        this.comboEmpresa = data;
      });
    }else {
      this.comboEmpresa = [];
      this.comboEmpresa.push(JSON.parse(user.toString(CryptoJS.enc.Utf8)).company);
    }


    var that = this;
    window.setTimeout(function (spinner) {
      that.spinner.hide();
    }, 500);
    // let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'a@Gt*kjnFRPf');
    // this.perfil = JSON.parse(user.toString(CryptoJS.enc.Utf8)).profile;
    console.log(this.perfil);
  }

  salvarUsuario() {
    this.spinner.show();
    for (var prop in this.usuario) {
      if (this.usuario[prop] == 'undefined' || this.usuario[prop] === '') {
        this.usuario[prop] = null;
      }
    }
    if (this.usuario.id != null) {
      this.usuarioService.atualizarUsuario(this.usuario).subscribe(data => {
        if (data != null) {
          this.toasty.success('Usuário salvo com sucesso!');
          this.router.navigate(['usuario']);
        }

        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });

      }, (err) => {
        try {
          if (err.error.errorCode == 10) {
            this.toasty.error('Usuário com email já existente');
          } else {
            this.toasty.error('Erro ao cadastrar usuário');
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar usuário');
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        }
      });
    } else {
      this.usuarioService.salvarUsuario(this.usuario).subscribe((data) => {
          if (data != null && this.usuario.profile) {
            this.toasty.success('Usuário salvo com sucesso!');
            this.router.navigate(['usuario']);
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        },
        (err) => {
          try {
            if (err.error.errorCode == 10) {
              this.toasty.error('Usuário com email já existente');
            } else {
              this.toasty.error('Erro ao cadastrar usuário');
            }
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });

          } catch (exeption) {
            this.toasty.error('Erro ao cadastrar usuário');
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
          }
        });
    }


  }

  addPhone() {
    let phone = new Phones();
    phone.phone = this.add_phone;
    phone.description = this.add_description;

    this.usuario.phones.push(phone);

    this.add_phone = null;
    this.add_description = null;
  }

  deletePhone(phone, description) {
    let new_phones = Array<Phones>();
    this.usuario.phones.forEach(obj => {
      if (!(obj.phone === phone && obj.description === description)) {
        new_phones.push(obj);
      }
    });

    this.usuario.phones = new_phones;

  }

  compararEmpresaCombo(item1, item2) {

    if (item1 == null && item2 == null) {
      return true;
    }

    try {
      return item1.id == item2.id;
    } catch (err) {
      return false;
    }
  }

  isCelular(numero): boolean {
    return numero == null ? false : numero.length <= 10 ? false : true;
  }

  getceluFixoMask(): string {
    return this.isCelular(this.add_phone) ? '([00]) [0000]-[0000]' : '([00]) [00000][0000]';
  }

  filtrar(): void{
    this.tadsService.getList('1', 'itensPerPage=5&keyword=' + this.keyword).subscribe(data => {
      this.list = data['results'];
      this.maxPages_list = data['numPages'];
      this.numData_list = data['count'];
      this.itensPerPage_list = data['itensPerPage'];
    });
  }


  openUser(contentUser) {
    this.spinner.show();

    this.modalService.open(contentUser, {size: 'lg'});
    this.tadsService.getList('1', 'itensPerPage=5').subscribe(data => {
      this.list = data['results'];
      this.maxPages_list = data['numPages'];
      this.numData_list = data['count'];
      this.itensPerPage_list = data['itensPerPage'];

      var that = this;
      window.setTimeout(function (spinner) {
        that.spinner.hide();
      });
    });
  }

  marcar() {
    this.tadsService.listTadSemPaginacao().subscribe(data => {
      this.usuario.tads = data;
      this.qtdadeTads = this.usuario.tads.length;
    });
  }

  desmarcar() {
    this.usuario.tads = new Array();
    this.qtdadeTads = this.usuario.tads.length;
  }

  getItemSelecionado(id) {
    var element = <HTMLInputElement>document.getElementById(id);
    var checked = element.checked;

    if (!checked) {
      for (let index = 0; index < this.usuario.tads.length; index++) {
        if (id.split('-')[1] == this.usuario.tads[index]['id']) {
          this.usuario.tads.splice(index, 1);
          this.qtdadeTads = this.usuario.tads.length;
          break;
        }

      }
    } else {
      this.usuario.tads.push({'id': id.split('-')[1]});
      this.qtdadeTads = this.usuario.tads.length;
    }

  }

  isChecked(tad_id): boolean{
    for (let index = 0; index < this.usuario.tads.length; index++) {
      if (tad_id == this.usuario.tads[index]['id']) {
        return true;
      }
    }
    return false;
  }

  navigatePage(new_page: string) {

    if (this.page < this.maxPages_list || new_page != '0') {
      this.spinner.show();

      let qs;
      if (this.keyword){
        qs = 'itensPerPage=5&keyword=' + this.keyword;
      }

      else{
        qs = 'itensPerPage=5';
      }

      this.tadsService.getList(new_page, qs).subscribe(data => {
        this.list = data['results'];
        this.maxPages_list = data['numPages'];
        this.numData_list = data['count'];
        this.itensPerPage_list = data['itensPerPage'];
        this.page = Number(new_page);

        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });


      });
    }
  }

}
