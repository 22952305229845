import { UsuarioService } from './../../service/usuario.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/model/Usuario';
import { BaseListComponent } from '../base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})

export class UsuarioComponent extends BaseListComponent implements OnInit {

  usuarios: Observable<Usuario[]>;
  fullName: string;
  email: string;
  isActive = true;

  public service;
  columns: any;
  filters: any;
  //public values = ["fullName","email", "profile", "isActive"]

  constructor(private usuarioService: UsuarioService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null, null, null, null, null);
    this.service = usuarioService;
  }

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Nome',
        value: 'fullName',
      },
      {
        nome: 'Email',
        value: 'email',
      },
      {
        nome: 'Empresa',
        value: 'company.name',
      },
      {
        nome: 'Perfil',
        value: 'profile'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'fullName'
      },
      {
        nome: 'Email',
        field: 'email'
      },
      {
        nome: 'Empresa',
        field: 'company'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      }
    ];
  }

  consultarUsuario() {
    this.usuarioService.consultarUsuario(this.fullName, this.email, this.isActive).subscribe(data => {
      this.usuarios = data['results'];
    });
  }
}
