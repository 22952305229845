import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
  ) { }

  listarDashboard(page= '1'): Observable<any> {
    return this.http.get<any>(AppConstants.listaDashboard + '?page=' + page,  {headers: AppConstants.getHeaders()});
  }

}
