import { ProtocoloService } from './../../service/protocolo.service';
import { ToastyService } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Protocolo } from 'src/app/model/Protocolo';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-novo-protocolo',
  templateUrl: './novo-protocolo.component.html',
  styleUrls: ['./novo-protocolo.component.css']
})
export class NovoProtocoloComponent implements OnInit {

  public protocolo = new Protocolo();


  constructor(
    private routeActive: ActivatedRoute,
    private protocoloService: ProtocoloService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService
  ) { }


  ngOnInit(): void {
    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');

    if (id != null) {
      this.protocoloService.carregarProtocolo(id).subscribe(data => {
        this.protocolo = data;
      });
    }

    var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
  }

  salvarProtocolo() {
    this.spinner.show();
    for (var prop in this.protocolo) {
      if (this.protocolo[prop] == 'undefined' || this.protocolo[prop] === ""){
        this.protocolo[prop] = null;
      }
    }

    if (this.protocolo.id != null) {
      this.protocoloService.atualizarProtocolo(this.protocolo).subscribe(data => {
        if (data != null) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });

          this.toasty.success('Protocolo salvo com sucesso');
          this.router.navigate(['protocolo']);
        }
      },
      (err) => {
        try {
          if (err.error.errorCode == 23) {
            this.toasty.error('Nome de protocolo já existente');
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        } catch (exeption) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
          this.toasty.error('Erro ao atualizar protocolo');
        }
      });
    } else {

      this.protocoloService.salvarProtocolo(this.protocolo).subscribe((data) => {
        if (data != null) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
          this.toasty.success('Protocolo salvo com sucesso');
          this.router.navigate(['protocolo']);

        }
      },
      (err) => {
        try {
          if (err.error.errorCode == 23) {
            var that = this;
            window.setTimeout(function (spinner) {
              that.spinner.hide();
            });
            this.toasty.error('Nome de protocolo já existente');
          }
        } catch (exeption) {
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
          this.toasty.error('Erro ao cadastrar protocolo');
        }
      });
    }


  }


}
