import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResultosICounterService {

  constructor(private http: HttpClient) { }

  getList(page: string, querystring = ""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.baseServidor + 'icounter-vet-exams/' + "?" + querystring , {headers: AppConstants.getHeaders()});
  }

  export(querystring = ""): Observable<any> {
    return this.http.get<any>(AppConstants.baseServidor + 'icounter-vet-exams/export/' + "?" + querystring , {headers: AppConstants.getHeaders()});
  }
}
