import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TadsService {

  constructor(private http: HttpClient) { }

  getList(page: string, querystring=""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listTads + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  salvarTads(tad): Observable<any> {
    return this.http.post<any>(AppConstants.salvarTads, tad, {headers: AppConstants.getHeaders()});
  }

  carregarTads(id): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/', {headers: AppConstants.getHeaders()});
  }

  carregarInicializacao(id): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/inits/total/', {headers: AppConstants.getHeaders()});
  }

  listQtdadeMensagens(id): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/messages/total/', {headers: AppConstants.getHeaders()});
  }

  carregarOffline(id): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/disconnects/total/', {headers: AppConstants.getHeaders()});
  }

  carregarHistInicializacoes(id, page="1"): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/inits/?pageSize=3&page='  + page, {headers: AppConstants.getHeaders()});
  }

  carregarHistConexao(id, page="1"): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/disconnects/?pageSize=3&page=' + page, {headers: AppConstants.getHeaders()});
  }

  listMensagens(id, page="1", querystring=""): Observable<any> {

    if (querystring != ""){
      querystring += "&pageSize=5&page=" + page;
    }
    else{
      querystring = "?pageSize=5&page=" + page;
    }
    return this.http.get<any>(AppConstants.listTads + id + '/messages/?' + querystring, {headers: AppConstants.getHeaders()});
  }

  listCombobox(id): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/message-types/', {headers: AppConstants.getHeaders()});
  }

  atualizarTads(tad): Observable<any> {
    return this.http.put<any>(AppConstants.salvarTads + tad.id + '/', tad, {headers: AppConstants.getHeaders()});
  }

  listLocalizacao(ip): Observable<any> {
    return this.http.get<any>(AppConstants.ipexterno + 'ip-info/?ip=' + ip, {headers: AppConstants.getHeaders()});
  }

  renovarTad(id): Observable<any> {
    return this.http.get<any>(AppConstants.renovar + id + '/renew-credentials-mqtt/', {headers: AppConstants.getHeaders()});
  }

  enviarComando(idTad, comando): Observable<any> {
    let data = {
      command: {
        id : comando.id,
        command: comando.command
      }
    }
    return this.http.post<any>(AppConstants.listTads + idTad + '/commands/', JSON.stringify(data), {headers: AppConstants.getHeaders()});
  }

  listarComando(): Observable<any> {
    return this.http.get<any>(AppConstants.listComando + '?noPaginate=1&delete=create_date&delete=create_user&orderBy=command&delete=description&isActive=1&delete=last_update_date&delete=last_update_user' , {headers: AppConstants.getHeaders()});
  }

  listaComando(idTad, page="1"): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + idTad + '/commands/?pageSize=5&page=' + page, {headers: AppConstants.getHeaders()});
  }

  listResultado(id, idTad): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + idTad + '/commands/' + id + '/', {headers: AppConstants.getHeaders()});
  }

  listTadSemPaginacao(): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + '?noPaginate=1&delete=name&delete=serial_number&delete=description&delete=mqtt_id&delete=mqtt_username&delete=mqtt_password&delete=last_sync&delete=disconnect_report&delete=company&delete=create_date&delete=create_user&delete=last_update_date&delete=last_update_user&delete=is_active' , {headers: AppConstants.getHeaders()});
  }

  listLog(id, page="1"): Observable<any> {
    return this.http.get<any>(AppConstants.listTads + id + '/logs/?pageSize=5&page=' + page, {headers: AppConstants.getHeaders()});
  }

}

