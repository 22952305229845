import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../app-constants';
import { Router, RouterModule } from '@angular/router';
import { ToastyService } from 'ng2-toasty';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
    ) { }

  login(usuario) {

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(AppConstants.baseLogin, JSON.stringify(usuario), headers).subscribe(data => {
      const dados = JSON.parse(JSON.stringify(data)).user;
      let user = CryptoJS.AES.encrypt(JSON.stringify(dados), 'a@Gt*kjnFRPf');
      localStorage.setItem('user', user.toString());

      let token = CryptoJS.AES.encrypt(JSON.parse(JSON.stringify(data)).token, 'a@Gt*kjnFRPf');
      localStorage.setItem('token', token.toString());

      this.router.navigate(['']);
    },
    erro => {
      this.toasty.error('Usuário ou senha inválida!');
    }
    );
  }
}
