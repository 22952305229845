import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  getEmpresaCombo(): Observable<any> {
    return this.http.get<any>(AppConstants.baseCombobox, {headers: AppConstants.getHeaders()});
  }

  getList(page: string, querystring=""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "?page="+page;
    }
    return this.http.get<any>(AppConstants.litEmpresa + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  salvarEmpresa(empresa): Observable<any> {
    return this.http.post<any>(AppConstants.salvarEmpresa, empresa, {headers: AppConstants.getHeaders()});
  }

  carregarEmpresa(id): Observable<any> {
    return this.http.get<any>(AppConstants.litEmpresa + id, {headers: AppConstants.getHeaders()});
  }

  atualizarUsuario(empresa): Observable<any> {
    return this.http.put<any>(AppConstants.litEmpresa + empresa.id + '/', empresa, {headers: AppConstants.getHeaders()});
  }

  getEndereco(cep): Observable<any> {
    return this.http.get<any>('https://api.postmon.com.br/v1/cep/' + cep);
  }

  // metodo para filtrar por tad:
  getTadCombo(): Observable<any> {
    return this.http.get<any>(AppConstants.ComboboxTad, {headers: AppConstants.getHeaders()});
  }

}
