import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { Comando } from './../model/Comando';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComandoService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
  ) { }


  getList(page: string, querystring=""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listComando + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  salvarComando(comando): Observable<any> {
    return this.http.post<any>(AppConstants.salvarComando, comando, {headers: AppConstants.getHeaders()});
  }

  carregarComando(id): Observable<any> {
    return this.http.get<any>(AppConstants.listComando + id + '/', {headers: AppConstants.getHeaders()});
  }

  atualizarComando(comando): Observable<any> {
    return this.http.put<any>(AppConstants.salvarComando + comando.id + '/', comando, {headers: AppConstants.getHeaders()});
  }

  filtrarComando(name: string, isActive: boolean): Observable<any> {
    const headers = new HttpHeaders().append('Authorization', 'Token 36bbb878f1a983e05075e560fbcb9e672b8ce6cd');
    var params = new HttpParams()
    .set('name', name != null ? name : '')
    .set('isActive', isActive != false ? '1' : '0');

    return this.http.get<any>(AppConstants.listComando, { headers: AppConstants.getHeaders(), params: params });
  }

}
