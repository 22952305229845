import { Router } from '@angular/router';
import { EsqueciMinhaSenhaService } from './../../service/esqueci-minha-senha.service';
import { Component, OnInit } from '@angular/core';
import { ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-esqueci-minha-senha',
  templateUrl: './esqueci-minha-senha.component.html',
  styleUrls: ['./esqueci-minha-senha.component.css']
})
export class EsqueciMinhaSenhaComponent implements OnInit {

  usuario = {email: ''};

  constructor(
    private esqueciMinhaSenhaService: EsqueciMinhaSenhaService,
    private router: Router,
    private toasty: ToastyService,
  ) { }

  ngOnInit(): void {
  }

  redefinirSenha() {
    if (this.usuario) {
      this.esqueciMinhaSenhaService.redefinirSenha(this.usuario);
    }
  }

}
