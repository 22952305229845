import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class EsqueciMinhaSenhaService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
  ) { }

  redefinirSenha(usuario) {

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(AppConstants.baseRedefinirSenha, JSON.stringify(usuario), headers).subscribe(data => {
      this.toasty.success('Enviamos um link para redefinir a sua senha no email informado. Caso não tenha recebido, verifique sua caixa de spam.');
      this.router.navigate(['login']);
    }, (err) => {
          this.toasty.error('E-mail invalido');
    });

  }
}
