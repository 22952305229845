import { Router } from '@angular/router';
import { HttpHeaders } from "@angular/common/http";

import * as CryptoJS from 'crypto-js';

export class AppConstants {

  public static getHeaders(): HttpHeaders {

    if (localStorage.getItem('token') !== null) {
      let token = CryptoJS.AES.decrypt(localStorage.getItem('token'), 'a@Gt*kjnFRPf');
      token = token.toString(CryptoJS.enc.Utf8);

      let headers = new HttpHeaders().append('Authorization', "Token " + token).append('Content-Type', 'application/json');
      return headers;
    }
    document.location.href = '/login';


  }

  // URL Produção
  public static get baseServidor(): string {
    return 'https://iopoc.celer.ind.br:8080/api/v1/';
  }

  // URL Homologaçãod
  public static get baseServidor2(): string {
    return 'https://iopoc.celer.ind.br:8080/api/v1/';
  }

  public static get baseCombobox(): string {
    return this.baseServidor + 'companies/?noPaginate=1&delete=cnpj&delete=email&delete=responsible_name&delete=responsible_email&delete=responsible_phone&delete=street&delete=number&delete=complement&delete=neighborhood&delete=city&delete=state&delete=country&delete=zipcode&delete=phones&delete=create_date&delete=create_user&deletey=last_update_date&delete=last_update_user&isActive=1&orderBy=name';
  }



  /**
   * URL para login e resetar a senha
   */
  public static get baseLogin(): string {
    return this.baseServidor + 'auth/user/login/';
  }

  public static get baseRedefinirSenha(): string {
    return this.baseServidor + 'auth/user/password_reset/';
  }

  public static baseRedefinirSenhaLogado(id: string): string {
    return this.baseServidor + '/users/' + id + '/change_password/';
  }

  public static baseRedefinirSenhaDeslogado(): string {
    return this.baseServidor + '/auth/user/password_change/';
  }

  /**
   * URL para CRUD de Usuario
   */
   public static get listUsuarios(): string {
     return this.baseServidor + 'users/';
   }

  public static get baseUrl(): string {
    return this.baseServidor + 'users/';
  }

  /**
   * URL para CRUD de Fabricantes
   */
  public static get listFabricantes(): string {
    return this.baseServidor + 'manufacturers/';
  }

 public static get salvarFabricantes(): string {
   return this.baseServidor + 'manufacturers/';
 }

 /**
  * URL para CRUD de Comando
  */

  public static get listComando(): string {
    return this.baseServidor + 'tad-commands/';
  }

  public static get salvarComando(): string {
    return this.baseServidor + 'tad-commands/';
  }


  /**
   * URL para CRUD de Equipamento
   */

   public static get listEquipamento(): string {
     return this.baseServidor + 'equipments/';
   }

   public static get salvarEquipamento(): string {
    return this.baseServidor + 'equipments/';
  }


   /**
    * URL para CRUD de Protocolo
    */

    public static get listProtocolo(): string {
      return this.baseServidor + 'protocols/';
    }

    public static get salvarProtocolo(): string {
      return this.baseServidor + 'protocols/';
    }

    /**
    * URL para CRUD de Empresa
    */

    public static get litEmpresa(): string {
      return this.baseServidor + 'companies/';
    }

    public static get salvarEmpresa(): string {
      return this.baseServidor + 'companies/';
    }

    /**
     * URL para CRUD de Tads
     */

    public static get listTads(): string {
      return this.baseServidor + 'tads/';
    }

    public static get salvarTads(): string {
      return this.baseServidor + 'tads/';
    }

    /**
     * Listagem de mensagens de TADS
     */
    public static get listTadsMessages(): string {
      return this.baseServidor + 'tads';
    }

    public static get renovar(): string {
      return this.baseServidor + 'tads/';
    }

    public static get ComboboxTad(): string {
      return this.baseServidor + 'tads/?delete=serial_number&delete=description&delete=mqtt_id&delete=mqtt_username&delete=mqtt_password&delete=last_sync&delete=disconnect_report&delete=company&delete=create_date&delete=create_user&delete=last_update_date&delete=last_update_user&delete=is_active&noPaginate=1';
    }

    public static get ipexterno(): string {
      return this.baseServidor + 'tads/';
    }

    /**
     * Listagem de Dashboard
     */
    public static get listaDashboard(): string {
      return this.baseServidor + 'dashboard/';
    }

}
