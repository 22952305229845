import { Branch } from './Branch';
import { Audit } from './Audit';
import { EmpresaFone } from './EmpresaFone';

export class Empresa extends Audit {
  id: number;
  name: String; // filtro
  cnpj: String; // filtro
  email: String;
  responsibleName: String;
  responsibleEmail: String;
  responsiblePhone: String;
  street: String;
  number: String;
  complement: String;
  neighborhood: String;
  city: String;
  state: String;
  country: String;
  zipcode: String;
  phones: Array<EmpresaFone>;
  branch: Array<Branch>;
  isActive: boolean; // filtro
}
