import { Protocolo } from './Protocolo';
import { Fabricante } from './Fabricante';
import { Audit } from './Audit';
export class Equipamento extends Audit {
  id: number;
  name: String;
  manufacturer: Fabricante;
  serialNumber: String;
  protocol: Protocolo;
}
