import { Observable } from 'rxjs';
import { ProtocoloService } from './../../service/protocolo.service';
import { Component, OnInit } from '@angular/core';
import { Protocolo } from 'src/app/model/Protocolo';
import { BaseListComponent } from '../base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-protocolo',
  templateUrl: './protocolo.component.html',
  styleUrls: ['./protocolo.component.css']
})
export class ProtocoloComponent extends BaseListComponent implements OnInit {

  protocolo: Observable<Protocolo[]>;
  name: string;
  description: string;
  isActive = true;
  service: any;
  columns: any;
  filters: any;

  constructor(private protocoloService: ProtocoloService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null,null, null, null, null, null);
    this.service = protocoloService;
   }

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Nome',
        value: 'name'
      },
      {
        nome: 'Descrição',
        value: 'description'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'name'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      }
    ];
  }
}
