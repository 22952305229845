import { Component, OnInit } from '@angular/core';
import {ResultosAbacusService} from '../../service/resultados-abacus.service';
import {BaseListComponent} from '../base-list/base-list.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {ResultosGasometriaService} from '../../service/resultados-gasometria.service';

@Component({
  selector: 'app-gasometria',
  templateUrl: './gasometria.component.html',
  styleUrls: ['./gasometria.component.css']
})
export class GasometriaComponent extends BaseListComponent implements OnInit {

  constructor(
    private resultadosService: ResultosGasometriaService,
    private baseListComponent: BaseListComponent,
    spinner: NgxSpinnerService
  ) {
    super(spinner, null, null, null, null, null, null);
    this.service = resultadosService;
  }

  ngOnInit(): void {
    this.columns = [
      { nome: 'IDENTIFICADOR', value: 'messageControlId' },
      { nome: 'DATA DE RECEBIMENTO', value: 'reportDate' },
      { nome: 'TAD', value: 'tadName', },
      { nome: 'EMPRESA', value: 'companyName' },
    ];

    this.filters = [
      {
        nome: 'Empresa',
        field: 'company'
      },
      {
        nome: 'TAD',
        field: 'tad'
      },
      {
        nome: 'Identificador',
        field: 'identifier'
      },
      {
        nome: 'Data Teste DE',
        field: 'sendDatetimeEquipmentGte'
      },
      {
        nome: 'Data Teste ATÉ',
        field: 'sendDatetimeEquipmentLte'
      },
    ];
  }

}
