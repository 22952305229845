import { data } from 'jquery';
import { HttpClient } from '@angular/common/http';
import { Phones } from './../../model/Phones';
import { EmpresaService } from './../../service/empresa.service';
import { ToastyService } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { Empresa } from './../../model/Empresa';
import { Component, OnInit } from '@angular/core';
import { EmpresaFone } from 'src/app/model/EmpresaFone';
import { NgxSpinnerService } from 'ngx-spinner';
import { Console } from 'console';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-novo-empresa',
  templateUrl: './novo-empresa.component.html',
  styleUrls: ['./novo-empresa.component.css']
})
export class NovoEmpresaComponent implements OnInit {

  public email = new FormControl('', Validators.email);

  public responsibleEmail = new FormControl('', Validators.email);

  public empresa = new Empresa();
  public phones = new EmpresaFone();

  public add_phone: String;
  public add_description: String;

  public comboEmpresa;

  readonly apiURL : string;

  constructor(
    private routeActive: ActivatedRoute,
    private empresaService: EmpresaService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService,
    private http: HttpClient
  ) {
   }

  ngOnInit(): void {
    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');

    if (id != null) {
      this.empresaService.carregarEmpresa(id).subscribe(data => {
        this.empresa = data;
      });
    } else {
      this.empresa.phones = new Array<EmpresaFone>();
    }

    var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);

      this.empresaService.getEmpresaCombo().subscribe(data => {
        this.comboEmpresa = data;
      });
  }

  onFocusOutEvent(event: any) {
    if (this.empresa.zipcode != null) {
      this.empresaService.getEndereco(this.empresa.zipcode).subscribe(data => {
        this.empresa.neighborhood = data.bairro;
        this.empresa.street = data.logradouro;
        this.empresa.city = data.cidade;
        this.empresa.state = data.estado;
        this.empresa.country = 'Brasil';
      }, (err) => {
        this.empresa.neighborhood = null;
        this.empresa.street = null;
        this.empresa.city = null;
        this.empresa.state = null;
        this.empresa.country = null;
      });
    }
  }

  salvarEmpresa() {
    this.spinner.show();

    for (var prop in this.empresa) {
      if (this.empresa[prop] == 'undefined' || this.empresa[prop] === ""){
        this.empresa[prop] = null;
      }
    }

    if (this.empresa.id != null) {

      this.empresaService.atualizarUsuario(this.empresa).subscribe(data => {
        if (data != null) {
          this.toasty.success('Empresa salvo com sucesso!');
          this.router.navigate(['empresa']);
        }
      }, (err) => {
        try {
          if (err.error.errorCode == 14) {
            this.toasty.error('CNPJ Invalido');
          } else if (err.error.errorCode == 13) {
            this.toasty.error('CNPJ já existente');
          }
        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar empresa');
        }
      });
    } else {
      this.empresaService.salvarEmpresa(this.empresa).subscribe((data) => {
        if (data != null) {
          this.toasty.success('Empresa salvo com sucesso');
          this.router.navigate(['empresa']);
        }
      }, (err) => {
        try {
          if (err.error.errorCode == 14) {
            this.toasty.error('CNPJ Inválido');
          }else if(err.error.errorCode == 13) {
            this.toasty.error('CNPJ já existente');
          } else {
            this.toasty.error('Erro ao cadastrar empresa');
          }
        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar empresa');
        }
      });
    }

    var that = this;
    window.setTimeout(function(spinner){
      that.spinner.hide();
    }, 100);
  }

  addPhone() {
    let phone = new EmpresaFone();
    phone.phone = this.add_phone;
    phone.description = this.add_description;

    this.empresa.phones.push(phone);

    this.add_phone = null;
    this.add_description = null;
  }

  deletePhone(phone, description) {
    let new_phones = Array<EmpresaFone>();
    this.empresa.phones.forEach(obj => {
      if (!(obj.phone === phone && obj.description === description)) {
        new_phones.push(obj);
      }
    });

    this.empresa.phones = new_phones;

  }

  compararCombo(item1,item2){

    if (item1 == null && item2 == null){
      return true;
    }

    try {
      return item1.id == item2.id
    }
    catch(err) {
      return false;
    }
  }

cnpj(cnpj): boolean {
  return cnpj == null ? true : this.cnpj.length < 12 ? true : false;
}

getCnpjMask(): string {
  return this.cnpj(this.empresa.cnpj) ? '[00].[000].[000]/[0000]-[00]' : '[00].[000].[000]/[0000]-[00]';
}

isCelular(numero): boolean {
  return numero == null ? false : numero.length <= 10 ? false : true;
}

getceluFixoMask(): string {
  return this.isCelular(this.add_phone) ? '([00]) [0000]-[0000]' : '([00]) [00000][0000]';
}

}
