import { Tads } from './Tads';
import { Empresa } from './Empresa';
import { Audit } from './Audit';
import { Phones } from './Phones';
export class Usuario extends Audit {
  id: number;
  fullName: String;
  email: String;
  profile: String;
  phones: Array<Phones>;
  company: Empresa;
  tads: Array<Object>;
}
