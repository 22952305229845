import { TrocarSenha } from './../../model/TrocarSenha';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RedefinirSenhaService } from './../../service/redefinir-senha.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.css']
})
export class TrocarSenhaComponent implements OnInit {

  public trocarSenha = new TrocarSenha();

  public senha: string;
  public confirmarPassword: string;

  constructor(
    private redefinirSenhaService: RedefinirSenhaService,
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService,
    public spinner: NgxSpinnerService

    ) { }

  ngOnInit(): void {
  }

  salvar() {
    this.spinner.show();
    if (this.senha === this.confirmarPassword) {
      this.trocarSenha.newPassword = this.confirmarPassword;

        let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'a@Gt*kjnFRPf');
        let id = JSON.parse(user.toString(CryptoJS.enc.Utf8)).id;

      this.redefinirSenhaService.getRedefinirSenha(this.trocarSenha, id).subscribe(data => {
        this.toasty.success('Senha alterada com sucesso!');
        this.router.navigate(['usuario']);
      });
    } else {
      this.toasty.error('A senha não confere');
      this.router.navigate(['trocar-senha']);
    }
    var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    }, 300);
  }

}
