import { LoginServiceService } from './../../service/login-service.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  public usuario: String;
  constructor(location: Location,  private element: ElementRef, private router: Router, loginServiceService: LoginServiceService) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    if (localStorage.getItem('user')) {
      let user = CryptoJS.AES.decrypt(localStorage.getItem('user'), 'a@Gt*kjnFRPf');
      this.usuario = JSON.parse(user.toString(CryptoJS.enc.Utf8)).email;
    }else {
      this.router.navigate(['/login']);
    }

  }
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    //return 'Dashboard';
    return titlee.replace('/', '').replace('-', ' ');
  }

  onLogout() {
    this.router.navigate(['/login']);
    localStorage.clear();
  }

}
