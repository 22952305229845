import { NgxSpinnerService } from 'ngx-spinner';
import { BaseListComponent } from './../base-list/base-list.component';
import { ResultadosIponaticService } from './../../service/resultados-iponatic.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { TadMessage } from 'src/app/model/TadMessage';

@Component({
  selector: 'app-resultados-iponatic',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.css']
})
export class ResultadosIponaticComponent extends BaseListComponent implements OnInit {

  tadMessage: Observable<TadMessage[]>;

  public resultado;

  constructor(
    private resultadosService: ResultadosIponaticService,
    private baseListComponent: BaseListComponent,
    spinner: NgxSpinnerService
  ) {
    super(spinner, null,null, null, null, null, null);
    this.service = resultadosService;
  }

  ngOnInit(): void {

    this.columns = [
      { nome: 'DATA DE RECEBIMENTO', value: 'createDate' },
      { nome: 'DATA TESTE', value: 'date' },
      { nome: 'PROJETO', value: 'Project' },
      { nome: 'IDADE', value: 'age' },
      { nome: 'CT CY5', value: 'ctCy5' },
      { nome: 'THRESHOLD CY5', value: 'thresholdCy5' },
      { nome: 'CT FAM', value: 'ctFam' },
      { nome: 'THRESHOLD FAM', value: 'thresholdFam' },
      { nome: 'CT ROX', value: 'ctRox' },
      { nome: 'THRESHOLD ROX', value: 'thresholdRox' },
      { nome: 'GENÊRO', value: 'genre' },
      { nome: 'CÓDIGO REAGENTE', value: 'reagenteCode' },
      { nome: 'RESULTADO', value: 'result' },
      { nome: 'AMOSTRA', value: 'sample' },
      { nome: 'CÓDIGO AMOSTRA', value: 'sampleCode' },
      { nome: 'NOME', value: 'tad.name', },
      { nome: 'EMPRESA', value: 'company' },
      { nome: 'TELEFONE', value: 'sendByPhone' }
    ];

    this.filters = [
      {
        nome: 'Empresa',
        field: 'company'
      },
      {
        nome: 'TAD',
        field: 'tad'
      },
      {
        nome: 'Projeto',
        field: 'project'
      },
      {
        nome: 'Código amostra',
        field: 'sampleCode'
      },
      {
        nome: 'Data Teste DE',
        field: 'sendDatetimeEquipmentGte'
      },
      {
        nome: 'Data Teste ATÉ',
        field: 'sendDatetimeEquipmentLte'
      },
    ];
  }

}


