import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from './../../service/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit {

  public dasboard;
  public tad;
  public mensagem;
  public list;
  public qtdade;
  public page_number = 1;

  constructor(
    private routeActive: ActivatedRoute,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService,
    public dashboardService: DashboardService,
    private http: HttpClient
  ) { }

   public page = 1;
   public maxPages;
   public numData;
   public itensPerPage;

  ngOnInit(): void {
    this.spinner.show();

    this.dashboardService.listarDashboard().subscribe(data => {
      this.dasboard = data['alerts']['results'];
      this.tad = data['tads'];
      this.qtdade = data['alerts']['count'];
      this.maxPages = data['alerts']['numPages'];
      this.mensagem = data['messages'];

      var that = this;
      window.setTimeout(function(spinner) {
        that.spinner.hide();
    });

    });
  }

  navigatePage(new_page: string) {
    if (this.page_number < this.maxPages || new_page != '0') {
        this.spinner.show();
        this.dashboardService.listarDashboard(new_page).subscribe(data => {
          this.dasboard = data['alerts']['results'];
          this.qtdade = data['alerts']['count'];
          this.maxPages = data['alerts']['numPages'];
          this.page_number = Number(new_page);
        });

      var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      });
    }
  }

}
