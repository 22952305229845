import { ComandoService } from './../../service/comando.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Comando } from 'src/app/model/Comando';
import { BaseListComponent } from '../base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-comando',
  templateUrl: './comando.component.html',
  styleUrls: ['./comando.component.css']
})
export class ComandoComponent extends BaseListComponent implements OnInit {

  comando: Observable<Comando[]>;
  name: string;
  isActive = true;
  columns: any;
  filters: any;
  update = true;


  constructor(private comandoService: ComandoService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null, null,null, null, null);
    this.service = comandoService;
  }

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Comando Linux',
        value: 'command'
      },
      {
        nome: 'Descrição',
        value: 'name'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Comando Linux',
        field: 'command'
      },
      {
        nome: 'Descrição',
        field: 'name'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      }

    ]

  }

  consultarComando() {
    this.data = null;
    setTimeout(() => this.update = false);
    setTimeout(() => this.update = true);
  }
}
