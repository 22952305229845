import { Fabricante } from './../../model/Fabricante';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ToastyService } from 'ng2-toasty';
import { FabricanteService } from 'src/app/service/fabricante.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-novo-fabricante',
  templateUrl: './novo-fabricante.component.html',
  styleUrls: ['./novo-fabricante.component.css']
})
export class NovoFabricanteComponent implements OnInit {

  constructor(
    private routeActive: ActivatedRoute,
    private fabricanteService: FabricanteService,
    private toasty: ToastyService,
    private router: Router,
    public spinner: NgxSpinnerService
  ) { }

  public fabricante = new Fabricante();

  ngOnInit(): void {
    this.spinner.show();
    let id = this.routeActive.snapshot.paramMap.get('id');

    if (id != null) {
      this.fabricanteService.carregarFabricante(id).subscribe(data => {
        this.fabricante = data;
      });
    }
    var that = this;
      window.setTimeout(function(spinner){
        that.spinner.hide();
      }, 300);
  }

  salvarFabricante() {

    this.spinner.show();

    for (var prop in this.fabricante) {
      if (this.fabricante[prop] == 'undefined' || this.fabricante[prop] === ""){
        this.fabricante[prop] = null;
      }
    }

    if (this.fabricante.id != null) {
      this.fabricanteService.atualizarFabricante(this.fabricante).subscribe(data => {
        if (data != null) {
          this.toasty.success('Fabricante salvo com sucesso');
          this.router.navigate(['fabricante']);
        }
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      }, (err) => {
        try {
          if (err.error.errorCode == 24) {
            this.toasty.error('Nome de fabricante já existente');
          } else {
            this.toasty.error('Nome de fabricante já existente');
          }

          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });

        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar fabricante');
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        }
      });
    } else {
      this.fabricanteService.salvarFabricante(this.fabricante).subscribe((data) => {
        if (data != null) {
          this.toasty.success('Fabricante salvo com sucesso');
          this.router.navigate(['fabricante']);
        }
        var that = this;
        window.setTimeout(function (spinner) {
          that.spinner.hide();
        });
      }, (err) => {
        try {
          if (err.error.errorCode == 24) {
            this.toasty.error('Nome de fabricante já existente');
          }else if(err.error.errorCode == 24) {
            this.toasty.error('Nome de fabricante já existente');
          } else {
            this.toasty.error('Erro ao cadastrar fabricante');
          }
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        } catch (exeption) {
          this.toasty.error('Erro ao cadastrar fabricante');
          var that = this;
          window.setTimeout(function (spinner) {
            that.spinner.hide();
          });
        }
      });
    }
  }
}
