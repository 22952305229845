import { BaseListComponent } from './../base-list/base-list.component';
import { TadsService } from './../../service/tads.service';
import { Observable } from 'rxjs';
import { Tads } from './../../model/Tads';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-tads',
  templateUrl: './tads.component.html',
  styleUrls: ['./tads.component.css']
})
export class TadsComponent extends BaseListComponent implements OnInit {

  tads: Observable<Tads[]>;

  service: any;
  columns: any;
  columnsUser: any;

  constructor(private tadsService: TadsService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null, null, null, null, null);
    this.service = tadsService;
   }

  ngOnInit(): void {

    this.columnsUser = [
      {
        nome: 'Nome',
        value: 'name',
      },
      {
        nome: 'Serial',
        value: 'serialNumber',
      },
      {
        nome: 'Empresa',
        value: 'company.name',
      }

    ];


    this.columns = [
      {
        nome: 'Nome',
        value: 'name',
      },
      {
        nome: 'Serial',
        value: 'serialNumber',
      },
      {
        nome: 'MQTT ID',
        value: 'mqttId'
      },
      {
        nome: 'Empresa',
        value: 'company.name',
      },

      {
        nome: 'Status',
        value: 'status'
      },

      {
        nome: 'Última Comunicação',
        value: 'lastSync'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'name',
      },
      {
        nome: 'Número Serial',
        field: 'serialNumber',
      },
      {
        nome: 'Empresa',
        field: 'company',
      },
      {
        nome: 'Ativo',
        field: 'isActive',
      },
    ]
  }

}
