import { AppConstants } from './../app-constants';
import { Observable } from 'rxjs';
import { ToastyService } from 'ng2-toasty';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquipamentoService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasty: ToastyService
  ) { }

  getList(page: string, querystring = ""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listEquipamento + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  salvarEquipamento(equipamento): Observable<any> {
    return this.http.post<any>(AppConstants.salvarEquipamento, equipamento, {headers: AppConstants.getHeaders()});
  }

  filtrarEquipamento(name: string, isActive: boolean): Observable<any> {
    const headers = new HttpHeaders().append('Authorization', 'Token 36bbb878f1a983e05075e560fbcb9e672b8ce6cd');
    var params = new HttpParams()
    .set('name', name != null ? name : '')
    .set('isActive', isActive != false ? '1' : '0');

    return this.http.get<any>(AppConstants.listEquipamento, { headers: AppConstants.getHeaders(), params: params });
  }

  carregarEquipamento(id): Observable<any> {
    return this.http.get<any>(AppConstants.listEquipamento + id + '/', {headers: AppConstants.getHeaders()});
  }

  atualizarEquipamento(equipamento): Observable<any> {
    return this.http.put<any>(AppConstants.salvarEquipamento + equipamento.id + '/', equipamento, {headers: AppConstants.getHeaders()});
  }

}
