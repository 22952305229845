import { AppConstants } from './../app-constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class UsuarioService {

  isVisualizar = false;

  constructor(private http: HttpClient) {
  }

  /**
   * Metodo para listar usuarios.
   */
  getList(page: string, querystring = ""): Observable<any> {
    if (querystring != ""){
      querystring += "&page=" + page;
    }
    else{
      querystring = "&page="+page;
    }
    return this.http.get<any>(AppConstants.listUsuarios + "?" + querystring, {headers: AppConstants.getHeaders()});
  }

  salvarUsuario(usuario): Observable<any> {
    return this.http.post<any>(AppConstants.baseUrl, usuario, {headers: AppConstants.getHeaders()});
  }

  atualizarUsuario(usuario): Observable<any> {
    return this.http.put<any>(AppConstants.baseUrl + usuario.id + '/', usuario, {headers: AppConstants.getHeaders()});
  }

  consultarUsuario(fullName: string,  email: string, isActive: boolean): Observable<any> {
    const headers = new HttpHeaders().append('Authorization', 'Token 36bbb878f1a983e05075e560fbcb9e672b8ce6cd');
    var params = new HttpParams()
    .set('fullName', fullName != null ? fullName : '')
    .set('email', email != null ? email : '')
    .set('isActive', isActive != false ? '1' : '0');

    return this.http.get<any>(AppConstants.baseUrl, { headers: headers, params: params });
  }

  carregarUsuario(id): Observable<any> {
    return this.http.get<any>(AppConstants.baseUrl + id, {headers: AppConstants.getHeaders()});
  }

  public visualizar() {
    this.isVisualizar = true;
  }

}
