import { Observable } from 'rxjs';
import { EquipamentoService } from './../../service/equipamento.service';
import { Component, OnInit } from '@angular/core';
import { Equipamento } from 'src/app/model/Equipamento';
import { BaseListComponent } from '../base-list/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-equipamento',
  templateUrl: './equipamento.component.html',
  styleUrls: ['./equipamento.component.css']
})
export class EquipamentoComponent extends BaseListComponent implements OnInit {

  equipamento: Observable<Equipamento[]>;
  name: string;
  isActive = true;
  columns: any;
  filters: any;
 /*  public columns = ["Nome", "Fabricante", "Protocolo","Número Serial", "Ativo"]
  public values = ["name",  "manufacturer.name", "protocol.name","serialNumber", "isActive"] */


  constructor(private equipamentoService: EquipamentoService, private baseListComponent: BaseListComponent, spinner: NgxSpinnerService) {
    super(spinner, null, null, null, null,null, null);
    this.service = equipamentoService;
  }

  ngOnInit(): void {
    this.columns = [
      {
        nome: 'Nome',
        value: 'name'
      },
      {
        nome: 'Fabricante',
        value: 'manufacturer.name'
      },
      {
        nome: 'Protocolo',
        value: 'protocol.name'
      },
      {
        nome: 'Número Serial',
        value: 'serialNumber'
      },
      {
        nome: 'Ativo',
        value: 'isActive'
      }
    ];

    this.filters = [
      {
        nome: 'Nome',
        field: 'name'
      },
      {
        nome: 'Número Serial',
        field: 'serialNumber'
      },
      {
        nome: 'Fabricante',
        field: 'manufacturer'
      },
      {
        nome: 'Protocolo',
        field: 'protocol'
      },
      {
        nome: 'Ativo',
        field: 'isActive'
      }
    ];
  }

  consultarEquipamento() {
    this.equipamentoService.filtrarEquipamento(this.name, this.isActive).subscribe(data => {
      this.equipamento = data['results'];
    });
  }
}
